import React, { useState } from "react";
// @ts-ignore
import i1Img from "../assets/img/tools.png";

// @ts-ignore
import slideImg1 from "../assets/img/workspace/workspace-fr-1.svg";
// @ts-ignore
import slideImg2 from "../assets/img/workspace/workspace-fr-2.svg";
// @ts-ignore
import slideImg3 from "../assets/img/workspace/workspace-fr-3.svg";
// @ts-ignore
import slideImg4 from "../assets/img/workspace/workspace-fr-4.svg";
// @ts-ignore
import arrowLeft from "../assets/img/arrow-left.png";
// @ts-ignore
import arrowRight from "../assets/img/arrow-right.png";
import VisibilitySensor from "react-visibility-sensor";
import useCarousel from "../hooks/useCarousel";

const slides = [
  {
    heading: "Déploiement en un clic",
    description:
      "Déployez Coachello en un clic auprès des participants au programme. Ils y ont accès en quelques minutes directement dans leur environnement de travail. Pas de connexion, de mot de passe, ni de formation nécessaire, c’est un environnement qu’ils utilisent au quotidien.",
    image: slideImg1,
  },
  {
    heading: "Analyse en temps réel",
    description:
      "Obtenez des informations en temps réel sur des données essentielles : utilisation, performance, changements de comportement et sujets de coaching..",
    image: slideImg2,
  },
  {
    heading: "Sécurité et protection de vos données renforcées",
    description:
      "Coachello est certifié SSL, M365 et ISO 27001:2013. Nous sommes en conformité RGPD. Nos serveurs sont hébergés en France. Nous garantissons donc une totale confidentialité et sécurité de vos données.",
    image: slideImg3,
  },
  {
    heading: "Adoption plus élevée",
    description:
      "En étant intégré au plus près des coachés, dans leur environnement de travail, nous observons un engagement et une adoption plus élevée que via une application mobile ou web.",
    image: slideImg4,
  },
];

const Workspace = () => {
  const {
    currentIndex,
    startAutoPlay,
    pauseAutoPlay,
    goToNextSlide,
    goToPreviousSlide,
  } = useCarousel({
    totalSlides: slides.length,
    autoPlayInterval: 5000,
  });

  const handleOnVisible = (isVisible: boolean) => {
    if (isVisible) {
      startAutoPlay();
    } else {
      pauseAutoPlay();
    }
  };

  return (
    <div className="bg-white section">
      <div className="flex flex-col items-center">
        <h2 className="mb-5 text-center">
          La puissance du coaching intégrée à l’environnement de travail
        </h2>
        <img className="h-8 px-5" src={i1Img} />
      </div>

      <div className="flex flex-col md:flex-row">
        <div className="relative flex-1 p-12">
          <img
            onClick={goToPreviousSlide}
            className="absolute left-0 w-8 h-8 my-auto cursor-pointer top-1/2"
            src={arrowLeft}
          />
          <img
            onClick={goToNextSlide}
            className="absolute right-0 w-8 h-8 my-auto cursor-pointer top-1/2"
            src={arrowRight}
          />

          <div className="flex overflow-hidden ">
            <div
              className="flex transition-transform duration-500 "
              style={{ transform: `translateX(-${currentIndex * 100}%)` }}
            >
              {slides.map((slide, index) => (
                <div
                  key={index}
                  className="w-full"
                  style={{ flex: `0 0 100%` }}
                >
                  <img src={slide.image} alt="Content" className="w-full" />
                </div>
              ))}
            </div>
          </div>
        </div>
        <VisibilitySensor onChange={handleOnVisible} delayedCall>
          <div className="flex flex-col items-start justify-center flex-1 p-8">
            <h4 className="mb-5">{slides[currentIndex].heading}</h4>
            <p>{slides[currentIndex].description}</p>
          </div>
        </VisibilitySensor>
      </div>
    </div>
  );
};

export default Workspace;

