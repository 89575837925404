import React from "react";
import HelpCard from "../components/HelpCard";
import CountUp from "react-countup";
import VisibilitySensor from "react-visibility-sensor";
// @ts-ignore
import sessionsIcon from "../assets/img/outcome/outcome5.png";
// @ts-ignore
import inprogressIcon from "../assets/img/outcome/outcome6.png";
// @ts-ignore
import satisfactionIcon from "../assets/img/outcome/outcome3.png";
// @ts-ignore
import growthIcon from "../assets/img/outcome/outcome7.png";
// @ts-ignore
import progressIcon from "../assets/img/outcome/outcome8.png";
// @ts-ignore
import roiIcon from "../assets/img/outcome/outcome9.png";

const Outcome = () => {
  return (
    <div className="section bg-warm">
      <h2 className="text-center mb-7">Axé sur les résultats</h2>
      <div className="grid lg:grid-cols-2 gap-5 lg:px-[8%]">
        <div className="flex flex-col gap-5">
          <HelpCard
            value={
              <CountUp suffix="+" end={5000} redraw={true} separator=",">
                {({ countUpRef, start }) => (
                  <VisibilitySensor onChange={start} delayedCall>
                    <span ref={countUpRef} />
                  </VisibilitySensor>
                )}
              </CountUp>
            }
            header="Séances de coaching réalisées"
            des=""
            icon={sessionsIcon}
          />
          <HelpCard
            valueSuffix="/5"
            value={
              <CountUp decimals={2} end={4.95} redraw={true}>
                {({ countUpRef, start }) => (
                  <VisibilitySensor onChange={start} delayedCall>
                    <span ref={countUpRef} />
                  </VisibilitySensor>
                )}
              </CountUp>
            }
            header="Satisfaction"
            des="Sur la relation avec son coach"
            icon={satisfactionIcon}
          />
          <HelpCard
            value={
              <CountUp suffix="%" end={85} redraw={true}>
                {({ countUpRef, start }) => (
                  <VisibilitySensor onChange={start} delayedCall>
                    <span ref={countUpRef} />
                  </VisibilitySensor>
                )}
              </CountUp>
            }
            header="Progrès moyen déclaré par les coachés"
            des="En moyenne, entre le début et la fin d’un coaching. Basé sur plus de 3000+ séances de coaching"
            icon={progressIcon}
          />
        </div>
        <div className="flex flex-col gap-5">
          <HelpCard
            value={
              <CountUp suffix="+" end={1200} redraw={true} separator=",">
                {({ countUpRef, start }) => (
                  <VisibilitySensor onChange={start} delayedCall>
                    <span ref={countUpRef} />
                  </VisibilitySensor>
                )}
              </CountUp>
            }
            header="Programmes de coaching en cours"
            des=""
            icon={inprogressIcon}
          />
          <HelpCard
            value={
              <CountUp suffix="%" start={1} end={65} redraw={true}>
                {({ countUpRef, start }) => (
                  <VisibilitySensor onChange={start} delayedCall>
                    <span ref={countUpRef} />
                  </VisibilitySensor>
                )}
              </CountUp>
            }
            header="Changement de comportements positifs"
            des="Observé par l’entourage professionnel"
            icon={growthIcon}
          />
          <HelpCard
            value={
              <CountUp suffix="x" end={3.5} redraw={true} decimals={1}>
                {({ countUpRef, start }) => (
                  <VisibilitySensor onChange={start} delayedCall>
                    <span ref={countUpRef} />
                  </VisibilitySensor>
                )}
              </CountUp>
            }
            header="ROI mesuré"
            des="Entre des talents ayant fait un coaching et ceux n’en ayant pas fait, les résultats sont basés sur une enquête menée auprès de 20+ clients accompagnant + de 100 personnes. Les variables comprennent la performance, le présentéisme, l'absentéisme et la rétention."
            icon={roiIcon}
          />
        </div>
      </div>
    </div>
  );
};

export default Outcome;
